import React, { useState, useEffect } from "react"
import loadable from "@loadable/component";
import { Form, Button } from "react-bootstrap"

import InputField from './elements/input'
import TextAreaField from './elements/textarea'
import CheckboxField from './elements/checkbox'
import ButtonField from './elements/button'
import HtmlBox from './elements/html'
import ReCaptchaBox from './elements/recaptcha'
import { postFormData, postStbFormData } from "./api/Api"
import { Mail_Function, raptorFormSchema } from "../common/utils"

const SelectField = loadable(() => import("./elements/select"));
// import axios from "axios"
// import * as qs from "query-string"

function NewsletterForm(props) {

  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showthankyou, setThankyou] = useState(false);

  const [formvalues, setFormvalues] = useState("");

  const [token, setToken] = useState("");

  const myRef = React.createRef();

  const recaptchaRef = React.createRef();

  //console.log("NameField",props.NameField);

  const fields = ([
      {
        element: "config",
        formname: "Newsletter - Subscribe",
        form_type: "subscribe",
        error_text: "Please enter valid email.",
        success_text: "Thank you for your interest. A member of our team will contact you shortly.",
        email_temp_user: "newsletter_user",
        email_temp_admin: "newsletter_admin",
        email_server_func: "newsletter",
        event_tracking: "subscribe",
        page_url: "/about-the-pearl-gates/case-studies-and-insights",
        email_subject_user: "Newsletter Subscription",
        email_subject_admin: "Newsletter Subscription"
      },
      {
        grpmd: "0",
        label: `Name`,
        placeholder: "Your Name",
        patternchk: "^[a-zA-Z0-9]+(\\s+[a-zA-Z0-9]+)*$",
        name: "name",
        type: "text",
        element: "input",
        required: true,
        patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
        labelClass: "content_b-18"
      },
      {
        grpmd: "0",
        label: "Enter email",
        placeholder: "Email Address",
        name: "email",
        type: "email",
        element: "input",
        required: true,
        patternchk:"[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
        class: "",
        grpcls: "d-flex align-items-center justify-content-between"
      },
      {
        name: "subscribe",
        type:"submit",
        element: "button",
        value: "Subscribe",
        class: "d-block btn btn-primary",
        customClass: ""
      },
      {
        element: "captcha",
        class: "py-2",
        captchaRef: recaptchaRef,
        customClass:'recaptcha_cls'
      },
    ]);

  const handlechange = event => {
    // remove initial empty spaces
    event.target.value = event.target.value.trimStart()
  }

  useEffect(() => {
    if (token !== '') {

 const processFromData = async () => {

      let formData = new FormData();

      formData.append('data', JSON.stringify(formvalues));

      postFormData(formData).then(async apiRes => {

        window.grecaptcha.reset();
        await Mail_Function(formvalues);
       
        
      });
      postStbFormData(raptorFormSchema(formvalues)).then(async apiRes => {  
        console.log('raptor submission', apiRes)
       });

      formvalues['g-recaptcha-response'] = token;

      // const axiosOptions_netlify = {
      //   url: fields[0].page_url,
      //   method: "post",
      //   headers: { "Content-Type": "application/x-www-form-urlencoded" },
      //   data: qs.stringify(formvalues),
      // }

      // axios(axiosOptions_netlify)
      //   .then(response => {
      //     console.log('data stored')
      //   })
      //   .catch(err =>
      //     console.log(err)
      //   );
      // const url = typeof window !== 'undefined' ? window.location.href : ''  
      // fields[0].formname = 'Newsletter';

      // tracking event
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'formSubmit',
        'formType': 'form-'+fields[0].event_tracking,
        'formId': 'form-'+fields[0].event_tracking,
        'formName': fields[0].formname,
        'formLabel': fields[0].formname
      });

      setShowerror(false);
      setThankyou(true);
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })

      }
      processFromData();

    }
  }, [token]);

  const handleonVerify = token => {
    console.log("captcha verified");
    setToken(token);
  };

  const handleSubmit = event => {

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
    }
    else {

      event.preventDefault();

      const formsdata = (event.target);
      const json = {}
      Object.keys(formsdata).map(key => (
        json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
      ))

      json['email_temp_user'] = fields[0].email_temp_user;
      json['email_temp_admin'] = fields[0].email_temp_admin;
      json['formname'] = fields[0].formname;
      json['g-recaptcha-response'] = token;
      json['email_subject_user'] = fields[0].email_subject_user;
      json['email_subject_admin'] = fields[0].email_subject_admin;

      setFormvalues(json);

      recaptchaRef.current.execute();

      setValidated(false);

      // reset form
      const form = event.target
      form.reset();
      
    }
  };

  return (
    <>

    {showerror && <div className="alert alert-danger">
      {fields[0].error_text}
    </div>}

    {showthankyou && <div className="alert alert-success">
      {fields[0].success_text}
    </div>}

    
      <Form className="d-md-flex" name={fields[0].formname} action="/thank-you/" method="post" noValidate validated={validated} onSubmit={handleSubmit} >
        <input type="hidden" name="form_name" value={fields[0].formname} />
        <input type="hidden" name="form_type" value={fields[0].form_type} />
        <input type="hidden" name="to_email_id" value={""} />
        <input type="hidden" name="bot-field" />

        {fields.map((field, index) => {
            if ( "input" === field.element ) {
              return (
                <InputField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  type={field.type}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  labelClass={field.labelClass}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  pattern={field.patternchk}
                  handlechange={handlechange}
                  grpcls={field.grpcls}
                />
              );
            }
            if ("select" === field.element) {
              return (
                <SelectField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  required={field.required}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  values={field.values}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                  componentprops={props}
                />
              );
            }
            if ("textarea" === field.element) {
              return (
                <TextAreaField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  rows={field.rows}
                  fieldClass={field.class}
                  labelClass={field.labelClass}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("checkbox" === field.element) {
              return (
                <CheckboxField
                  name={field.name}
                  ref={field.ref}
                  value={field.value}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("html" === field.element) {
              return (
                <HtmlBox
                  text={field.text}
                  fieldClass={field.class}
                  key={`${field.element}~${index}`}
                />
              );
            }
            if ("captcha" === field.element) {
              return (
                <ReCaptchaBox
                  fieldClass={field.class}
                  captRef={field.captchaRef}
                  key={`${field.element}~${index}`}
                  handleonVerify={handleonVerify}
                  customClass={field.customClass}
                />
              );
            }
            if ("button" === field.element) {
              return (
                <ButtonField
                  name={field.name}
                  fieldClass={field.class}
                  type={field.type}
                  value={field.value}
                  key={`${field.element}~${index}`}
                  customClass={field.customClass}
                />
              );
            }
          })
        }
            <div ref={myRef} />

      </Form>
    </>
  );
}


const NewsletterFormPage = (props) => (
  <NewsletterForm {...props} />
)

export default NewsletterFormPage