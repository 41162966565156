import { Link } from "@StarberryUtils";
import React from "react";
import {Container, Row, Col} from 'react-bootstrap';
//import ScrollAnimation from 'react-animate-on-scroll';
import { useStaticQuery, graphql } from "gatsby"
import NewsletterForm from '../forms/newsletter-form2';


// images
// Style
import  "../NewsLetter/NewsLetter.scss";
import useSiteConfig from "../../hooks/useSiteConfig";

const NewsLetter =() => {

  //   const data = useStaticQuery(graphql`
  //   query SiteConfigQuery {

  //     glstrapi {
  //       siteConfig {
  //         Newsletter_Content
  //         id
  //       }
  //     }

  //   }
  // `)

  //   var config_data = data.glstrapi.siteConfig;

  const {Newsletter_Content} = useSiteConfig()
    
    return(
        <div className="newsletter-section">
            <Container>
                <Row>
                    <Col className="p-0">
                     <div className="animated"> 
                        <div className="newsletter-block">
                            <h2>Join our newsletter</h2>
                            <p>{Newsletter_Content}</p>
                            <div className="form-blcok">
                            
                            <NewsletterForm />

                            <p className="form-text">By subscribing, you agree to our <Link href="/terms-and-conditions">Terms &amp; Conditions</Link> and <Link href="/privacy-policy">Privacy Policy</Link>.</p>
                            </div>
                        </div>
                     </div>
                    </Col>
                </Row>
            </Container>
        </div>
)
    }
export default NewsLetter;
